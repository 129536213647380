#session-detail-page {
  .session-track-ionic {
    color: var(--ion-color-primary);
  }

  .session-track-angular {
    color: var(--ion-color-angular);
  }

  .session-track-communication {
    color: var(--ion-color-communication);
  }

  .session-track-tooling {
    color: var(--ion-color-tooling);
  }

  .session-track-services {
    color: var(--ion-color-services);
  }

  .session-track-design {
    color: var(--ion-color-design);
  }

  .session-track-workshop {
    color: var(--ion-color-workshop);
  }

  .session-track-food {
    color: var(--ion-color-food);
  }

  .session-track-documentation {
    color: var(--ion-color-documentation);
  }

  .session-track-navigation {
    color: var(--ion-color-navigation);
  }

  .show-favorite {
    position: relative;
  }

  .icon-heart-empty {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(1);
    transition: transform 0.3s ease;
  }

  .icon-heart {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(0);
    transition: transform 0.3s ease;
  }

  .show-favorite .icon-heart {
    transform: scale(1);
  }

  .show-favorite .icon-heart-empty {
    transform: scale(0);
  }

  h1 {
    margin: 0;
  }
}
